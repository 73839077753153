import React, { useState, useEffect } from "react";
import Header from "./header";
import Loader from "../assests/icons/loader.svg";
import Plan from "./Plan";
import axios from "axios";
import SignUpToday from "../pages/SignUpToday";

const CuratingPlanAfterAge = ({ dialogText, profession, selectedAge, professionSummary }) => {
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedProfessionalSummary, setSelectedProfessionalSummary] = useState(professionSummary || ""); // Initialize with professionSummary prop
  const [loading, setLoading] = useState(false);
  const [showHomeComponent, setShowHomeComponent] = useState(false);


  const handleSubmit = () => {
    setShowHomeComponent(true);
  };

  const createPrompt = () => {
    let prompt = `You are an expert in defining a specific goal from a user input statement. Do not create goal for inappropriate user statement and make sure output is age appropriate. In case of inappropriate user input respond with inappropriate input.
    <user>
        {{user_statement}}
        {{#if user_age}}
        Age: {{user_age}}
        {{/if}}
        {{#if user_profession}}
        Profession: {{user_profession}}
        {{/if}}
    </user>
    
    Identify the goal from the user's statement. If goal can't be extracted respond with goal as null.
    <response as="goal_extracted" />
    
    {{#if goal_extracted}}
    Goal: {{goal_extracted}}
    Suggest plans to achieve the goal using the user's age and/or profession as additional criteria if provided. 
    Show the plans in JSON format with each plan's name, plan duration in month, How many day a week should do this activity, repetition of activity to make it a habit, deficulty level (1, 2 , 3, 4) and plan Detail in 1 line.
    <response as="activities" />
    {{:else}}
    No goal can be extracted from the statement.
    {{/if}}`;
    prompt = prompt.replace("{{user_statement}}", dialogText);
    prompt = prompt.replace("{{user_age}}", selectedAge);
    prompt = prompt.replace("{{user_profession}}", profession);
    return prompt;
  };

  const getPlans = async () => {
    if (loading) return;
    setLoading(true);
    setShowNewComponent(false);

    try {
      const res = await axios.post("https://api.trackhabit.io/api/generate", {
        label: createPrompt(),
        user_prompt: dialogText,
      });

      if (res.data?.activities) {
        setPlans(res.data.activities);
      } else {
        console.error("Invalid plans response structure", res.data);
        alert("No valid plans found. Please try again later.");
      }

      if (res.data?.professionalSummary) {
        setSelectedProfessionalSummary(res.data.professionalSummary); 
      } else {
        console.error("Invalid professional summary structure", res.data);
      }

      setShowNewComponent(true);
    } catch (error) {
      console.error("Error fetching plans:", error);
      alert("Something went wrong. Please try later!");
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    if (!loading && plans.length === 0 && !selectedProfessionalSummary) {
      getPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Fetch data on component mount

  if (loading) {
    return (
      <div className="relative h-full bg-custom-gray">
        <Header />
        <div className="w-max-[1076px] mx-auto bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center pt-120px sm:pt-auto frame-border-gradient">
          <div className="Plans-dialogue">
            <div className="w-[100%] sm:max-h-[632px] sm:h-full py-14 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-[72px] inline-flex px-4 sm:px-8">
              <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
                Fine-tuning your Personalized Plans
              </div>
              <div className="w-[100px] h-[100px] justify-center items-center inline-flex">
                <img src={Loader} className="spin-slow w-full h-full" alt="loader" />
              </div>
              <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                Our state-of-the-art AI is diligently working to fine-tune personalized
                <br />
                plans for you. This process will be completed shortly.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showNewComponent && plans.length > 0) {
    return (
      <Plan
        plans={plans}
        selectedProfessionalSummary={selectedProfessionalSummary} 
        selectedAge={selectedAge}
        profession={profession}
        dialogText={dialogText}
      />
    );
  }
  if (showHomeComponent) {
    return (
      <>
        <Header />
        <SignUpToday />
      </>
    );
  }

  return (
    <div className="relative h-full bg-custom-gray">
      <Header />
      <div className="w-max-[1076px] mx-auto bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center pt-120px sm:pt-auto frame-border-gradient">
        <div className="Plans-dialogue">
          <div className="w-[100%] sm:max-h-[632px] sm:h-full py-14 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-[72px] inline-flex px-4 sm:px-8">
            <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
              {selectedProfessionalSummary || "No valid plans found."}
            </div>
            <div className="self-stretch justify-center items-center gap-8 inline-flex">
              <button onClick={handleSubmit} className="w-[215px] text-white text-base font-normal font-['Outfit'] px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex">
                  Back to Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default CuratingPlanAfterAge;
